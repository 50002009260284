import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { SupportToken } from "../domain/SupportToken";
import { SupportTokenRepository } from "../domain/SupportTokenRepository";

export function createApiSupportTokenRepository(): SupportTokenRepository {
  return new ApiSupportTokenRepository();
}

export class ApiSupportTokenRepository
  extends APIRepository
  implements SupportTokenRepository {
  constructor() {
    super(vectiAppApiUrl());
  }


  getSupportToken(companyId: string): Promise<SupportToken> {
    try {
      const token = this.doGet<SupportToken>("/api/supports/token/company/" + companyId);
      console.log("token:" + JSON.stringify(token));
      return token;


    } catch (error) {
      console.log("error getReconciliation:" + error);
      return Promise.reject();
    }

  }



}
