import { UserExtended } from "../domain/UserExtended";
import { UserNotFoundError } from "../domain/UserNotFoundError";
import { UserRepository } from "../domain/UserRepository";

export function getUserExtendedData(
  userRepository: UserRepository,
  userUid: string
): Promise<UserExtended> {
  console.log("userUid: " + userUid);
  return new Promise<UserExtended>((resolve, reject) => {
    userRepository
      .getUserExtendedData(userUid)
      .then((user) => {
        console.log("user in :" + JSON.stringify(user));
        resolve(user);
      })
      .catch((_error) => {
        reject(new UserNotFoundError("User not found"));
      });
  });
}
