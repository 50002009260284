<template>
  <v-btn
    color="blue-darken-2"
    size="small"
    class="ma-1"
    variant="elevated"
    v-if="!isAuthenticated && !isLoading"
    @click="handleLogin"
  >
    Inicio Sesión
  </v-btn>
</template>

<script setup>
import { useAuth0 } from "@auth0/auth0-vue";
import { useRouter } from "vue-router";
const router = useRouter();

const { loginWithPopup, isAuthenticated, isLoading } = useAuth0();

const handleLogin = () => {
  try {
    loginWithPopup()
      .then((res) => {
        console.log("login-button: " + JSON.stringify(res));
        try {
          useUser();

          console.log("useUser llamado ");
          router.push("/start");
        } catch (e) {
          console.log("Error en useUser: " + e);
        }
      })
      .catch((err) => {
        console.error("XXXXError logging in", err);
      });
  } catch (error) {
    console.error("Error en el inicio de sesión con popup:", error);
  }
};
</script>
