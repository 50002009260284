<template>
  <div v-if="isAuthenticated && !isLoading">
    <v-btn
      v-if="store.getters.getShowLarge"
      color="red-darken-2"
      size="small"
      class="ma-1"
      variant="elevated"
      @click="logout"
    >
      Salir
    </v-btn>
    <v-btn
      v-else
      color="red-darken-2"
      size="small"
      class="ma-1"
      variant="elevated"
      v-if="isAuthenticated"
      @click="logout"
    >
      <v-icon icon="mdi-logout"> </v-icon>
    </v-btn>
  </div>
</template>

<script setup>
import { useAuth0 } from "@auth0/auth0-vue";

const { isAuthenticated, logout, isLoading } = useAuth0();

const handleLogout = () => {
  logout();
  globalUser.value = null;
  globalUserExtended.value = null;
};
</script>
