<template>
  <div
    v-if="
      store.getters.getInUseCompanyHasAccount || store.getters.getInUseCompanyHasTaxAgency
    "
  >
    <div v-if="isAuthenticated && !isLoading">
      <v-btn
        v-if="store.getters.getShowLarge"
        color="blue-darken-4"
        size="small"
        class="ma-1"
        variant="elevated"
        @click="handleLogin"
      >
        Suscríbete
      </v-btn>
      <v-btn
        v-else
        color="blue-darken-4"
        size="small"
        class="ma-1"
        variant="elevated"
        @click="handleLogin"
      >
        <v-icon icon="mdi-bell-ring"> </v-icon
      ></v-btn>
    </div>
  </div>
</template>

<script setup>
import { useAuth0 } from "@auth0/auth0-vue";
import { useRouter } from "vue-router";
const router = useRouter();

const { loginWithPopup, isAuthenticated, isLoading } = useAuth0();

const handleLogin = () => {
  try {
    console.log("Suscribete");
    router.push("/subscriptions");
  } catch (error) {
    console.error("Error en suscribete:", error);
  }
};
</script>
