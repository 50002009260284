import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { ReveniuSubscriptionRepository } from "../domain/ReveniuSubscriptionRepository";
import { ReveniuSubscription } from "../domain/ReveniuSubscription";
import { ReveniuSubscriptionId } from "../domain/ReveniuSubscriptionId";
import { SubscriptionId } from "~/context/Subscriptions/domain/SubscriptionId";
import { CompanyId } from "~/context/Company/domain/CompanyId";

export function createApiReveniuSubscriptionRepository(): ReveniuSubscriptionRepository {
  return new ApiReveniuSubscriptionRepository();
}

export class ApiReveniuSubscriptionRepository
  extends APIRepository
  implements ReveniuSubscriptionRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  async addReveniuSubscription(uid: ReveniuSubscriptionId, subscriptionId: SubscriptionId, companyId: CompanyId, subscriptionPlan: string): Promise<ReveniuSubscription> {
    console.log("call addReveniuSubscription:" + ", uid:" + uid.toString() + ", subscriptionId:" + subscriptionId.toString() + ", companyId:" + companyId.toString());
    try {
      const ret = await this.doGet<ReveniuSubscription>("/api/reveniusubscriptions/uid/" + uid.toString() + "/subscription_id/" + subscriptionId.toString() + "/company_uid/" + companyId.toString() + "/subscription_plan/" + subscriptionPlan);
      return ret;
    } catch (error) {
      console.log("error addSubscription:" + error);
      return Promise.reject();
    }
  }
}
