import { getPreCompanyByLegalId } from "~/context/PreCompanies/application/getPreCompanyByLegalId";
import { Dependencies } from "~/context/Shared/infrastructure/Dependencies";
import { getPreCompaniesByUser } from "~/context/PreCompanies/application/getPreCompaniesByUser";

export async function usePreCompanyByLegalId(
  legalId: string,
) {
  try {
    console.log("usePreCompanyByLegalId legalId:" + legalId);
    const ret = await getPreCompanyByLegalId(Dependencies.getInstance().getPreCompanyRepository(), legalId);
    return ret;
  } catch (error) {
    console.log("usePreCompanyByLegalId error", JSON.stringify(error));
    return null;
  }
}

export async function usePreCompanyByUser(userId: string) {
  try {
    console.log("usePreCompaniesByUser:");
    const ret = await getPreCompaniesByUser(Dependencies.getInstance().getPreCompanyRepository(), userId);
    return ret;
  } catch (error) {
    console.log("usePreCompanyByLegalId error", JSON.stringify(error));
    return null;
  }
}
