<template>
  <v-btn
    color="blue-darken-4"
    size="small"
    class="ma-1"
    variant="elevated"
    v-if="!isAuthenticated"
    @click="handleRegister"
  >
    Registrate
  </v-btn>
</template>

<script setup>
import { useAuth0 } from "@auth0/auth0-vue";
import { useRouter } from "vue-router";
const router = useRouter();
const { isAuthenticated } = useAuth0();

const handleRegister = () => {
  //  logout();
  console.log("register");
  const routeData = router.resolve({ path: "/register" });
  window.open(routeData.href, "_blank");
  //  globalUser.value = null;
  //  globalUserExtended.value = null;
};
</script>
