<template>
  <v-app>
    <Header />

    <SideBarMenu v-if="user_ex && isAuthenticated" />

    <v-main>
      <v-container class="ma-0 pa-1" max-width="100%">
        <Dialog v-if="isAuthenticated && vecti_user" />
        <Notification />
        <router-view :key="lastClickTime"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { useAuth0 } from "@auth0/auth0-vue";
import { useSynchcronizeBank } from "./composables/useSynchronize";
import { useRouter } from "vue-router";
import LoadingMessage from "./components/welcome/LoadingMessage.vue";
import { time } from "echarts";

export default {
  data() {
    return {
      loading: true,
      //userExtended: "no user ext",
      //userExtended: "cargando",
    };
  },
  computed: {
    lastClickTime() {
      return store.state.lastClickTime;
    },
  },
  watch: {
    vecti_user(newUser) {
      this.checkConditions(newUser, this.isAuthenticated);
    },
    isAuthenticated(newAuth) {
      //console.log("isAuthenticated changed!!: ");
      this.checkConditions(this.vecti_user, newAuth);
    },
    windowWidth(newWidth) {
      //console.log('windowSize changed X:', newWidth);
      if (newWidth < 300) {
        store.commit("setWindowSize", "xs");
        //console.log("windowSize tipo xs: " + store.getters.getWindowSize);
      } else if (newWidth < 500) {
        store.commit("setWindowSize", "sm");
        //console.log("windowSize tipo sm: " + store.getters.getWindowSize);
      } else if (newWidth < 700) {
        store.commit("setWindowSize", "m");
        //console.log("windowSize tipo m: " + store.getters.getWindowSize);
      } else {
        store.commit("setWindowSize", "l");
        //console.log("windowSize tipo l: " + store.getters.getWindowSize);
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    console.log("this.$router:" + this.$router.path);
    const route = useRoute();
    console.log("route.path: " + route.path);
    if (route.path === "/") {
      localStorage.removeItem("previousRoute");
    }
    //this.windowFirstSize(window.innerWidth);
    console.log("this.isAuthenticated : " + JSON.stringify(this.isAuthenticated));
    console.log("this.vecti_user : " + JSON.stringify(this.vecti_user?.message));
    //    if (this.isAuthenticated && this.vecti_user?.message === "Not Found") {
    // console.log("redireccionando a welcome");
    //this.$router.push("/welcome");
    //   } else {
    this.windowFirstSize(window.innerWidth);
    //    }
  },
  methods: {
    windowFirstSize(windowSize) {
      console.log("windowSize first:", windowSize);

      if (windowSize < 300) {
        store.commit("setWindowSize", "xs");
        console.log("windowSize tipo xs: " + store.getters.getWindowSize);
      } else if (windowSize < 500) {
        store.commit("setWindowSize", "sm");
        console.log("windowSize tipo sm: " + store.getters.getWindowSize);
      } else if (windowSize < 700) {
        store.commit("setWindowSize", "m");
        console.log("windowSize tipo m: " + store.getters.getWindowSize);
      } else {
        store.commit("setWindowSize", "l");
        console.log("windowSize tipo l: " + store.getters.getWindowSize);
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    checkConditions(user, auth) {
      console.log("user: " + JSON.stringify(user));
      console.log("auth: " + JSON.stringify(auth));
      if (auth && user?.message === "Not Found") {
        console.log("redireccionando a welcome");
        this.loading = false;
        this.$router.push("/welcome");
      } else if (auth) {
        const previousRoute = localStorage.getItem("previousRoute");
        if (previousRoute) {
          console.log("Restaurando ruta anterior:", previousRoute);
          this.$router.push(previousRoute);
          localStorage.removeItem("previousRoute");
        } else {
          console.log("redireccionando a start");
          this.$router.push("/start");
        }
        /*
        console.log("redireccionando a start");
        this.loading = false;
        console.log("-->" + this.$router);
        this.$router.push("/start");
        */
      } else {
        setTimeout(() => {
          this.loading = false;
        }, 500);

        console.log("no redireccionando a welcome");
      }
    },
  },
  setup() {
    let user_ex = null;
    let vecti_user = null;
    const { isAuthenticated } = useAuth0();
    const windowWidth = ref();
    const expand = ref(store.getters.getExpandMenu);
    const router = useRouter();

    console.log("expand: " + expand.value);
    try {
      console.log("llamando:");
      vecti_user = useUser();
      console.log("vecti_user APP.vue:" + JSON.stringify(vecti_user));
      user_ex = useUserExtended(vecti_user, router);
    } catch (e) {
      console.log("Error en vecti_user: " + e);
    }

    const changeExpandMenu = () => {
      expand.value = !expand.value;
      store.commit("setExpandMenu", expand.value);
    };

    return {
      changeExpandMenu,
      expand,
      user_ex,
      vecti_user,
      isAuthenticated,
      windowWidth,
    };
  },
};
</script>
