export class ConnectionRefusedError extends Error {
  readonly type: string;
  readonly code: number;
  constructor(
    message: string,
    type?: string,
    code?: number,
  ) {
    super(message);
    this.type = type || "ConnectionRefusedError";
    this.code = code || 500;
  }
}
