<template>
  <v-app-bar elevation="0" style="border-bottom: 0.1px solid grey">
    <v-toolbar color="#ffffff" class="pr-1">
      <v-btn
        v-if="isAuthenticated"
        :icon="`mdi-menu-${expand ? 'close' : 'open'}`"
        color="#1F2937"
        @click="changeExpandMenu"
        style="left: -5px"
      >
      </v-btn>

      <v-hover disabled>
        <div v-if="store.getters.getShowLarge">
          <img
            src="https://www.vecti.cl/wp-content/uploads/2022/09/cropped-03-logo-superior-1.png"
            alt="Logo"
            width="90"
            class="ml-5"
          />
        </div>
        <div v-else>
          <img src="/img/v-logo.png" class="logo-xs" />
        </div>
      </v-hover>
      <v-spacer></v-spacer>

      <div v-if="store.getters.getUserExtended && isAuthenticated" style="padding: 0px">
        <ShowUserCompanies
          :listCompanies="store.getters.getUserExtended.companies"
          :default_company="store.getters.getUserExtended.default_company"
        />
      </div>
      <v-spacer></v-spacer>

      <div v-if="!isAuthenticated">
        <LoginButton />
        <RegisterButton v-if="!isRegisterPath" />
      </div>

      <v-menu>
        <v-btn flat slot="activator" color="yellow">
          <v-icon icon="mdi-arrow-left" left></v-icon>
          <span>Menu</span>
        </v-btn>
      </v-menu>

      <div>
        <SubscriptionButton v-if="!store.getters.getInUseCompanyHasActiveSubscription" />
      </div>

      <div
        v-if="
          store.getters.getInUseCompanyHasAccount ||
          store.getters.getInUseCompanyHasTaxAgency
        "
      >
        <div>
          <v-btn
            color="green-darken-2"
            size="small"
            class="ma-1"
            variant="elevated"
            v-if="
              isAuthenticated &&
              (store.state.inUseCompany !== null || store.state.inUseCompany != '')
            "
          >
            <div v-if="store.getters.getShowLarge">
              <v-icon icon="mdi-arrow-down" left></v-icon>Actualizar {{ month }}
            </div>
            <div v-else>
              <v-icon icon="mdi-update" left></v-icon>
            </div>

            <v-menu activator="parent">
              <v-list>
                <v-list-item base-color="green-darken-3" size="small">
                  <v-list-item-title
                    @click="bank"
                    v-if="store.getters.getInUseCompanyHasAccount"
                    >Banco</v-list-item-title
                  >
                  <v-list-item-title
                    @click="sii"
                    v-if="store.getters.getInUseCompanyHasTaxAgency"
                    >SII</v-list-item-title
                  >
                  <v-list-item-title
                    @click="conciliation"
                    v-if="store.getters.getInUseCompanyHasAccount"
                    >Conciliación</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-btn>
        </div>
      </div>
      <LogoutButton />
    </v-toolbar>
  </v-app-bar>
</template>

<script setup>
import { useAuth0 } from "@auth0/auth0-vue";
import { onMounted } from "vue";

/*
onMounted(async () => {
  console.log("onmounted");
  try {
    if (isAuthenticated.value) {
      console.log("isAuthenticated.value: " + isAuthenticated.value);
      let vecti_user = useUser();
      console.log("vecti_user (header):" + JSON.stringify(vecti_user));
      user_ex = useUserExtended(vecti_user);
    }
  } catch (e) {
    console.log("Error en vecti_user (header): " + e);
  }
});
*/
const { isAuthenticated } = useAuth0();
watch(
  () => store.getters.getUserExtended,
  (newVal) => {
    console.log(
      "store.getters.getUserExtended.value (header): " + JSON.stringify(newVal)
    );
  }
);

/*
watch(
  () => isAuthenticated.value,
  (newVal) => {
    console.log("isAuthenticated.value (header): " + newVal);
    if (newVal) {
      let vecti_user = useUser();
      console.log("vecti_user (header):" + vecti_user);
      user_ex = useUserExtended(vecti_user);
    }
  }
);
*/
watch(
  () => store.getters.getInUseCompanyHasAccount,
  (newVal) => {
    console.log("store.getters.getInUseCompanyHasAccount (header): " + newVal);
  }
);

const expand = ref(store.getters.getExpandMenu);
const route = useRoute();
const isRegisterPath = computed(() => {
  const currentPath = route.path;

  console.log("currentPath: " + currentPath);
  if (currentPath === "/register") {
    return true;
  }
  return false;
});

//let user_ex = ref(null);
let user_ex = null;

const changeExpandMenu = () => {
  expand.value = !expand.value;
  store.commit("setExpandMenu", expand.value);
};

const bank = async () => {
  store.dispatch("openNotification", {
    message: "Actualizando información bancaria",
    duration: 1500,
  });
  try {
    console.log("Sincronizando información bancaria: " + store.state.inUseCompany);
    const vectiResult = await useSynchcronizeBank(store.state.inUseCompany);
    console.log("vectiResult: " + JSON.stringify(vectiResult));

    //store.dispatch("updateMenuSelection");
    if (vectiResult) {
      store.dispatch("openNotification", {
        message: "Sincronización bancaria exitosa",
        duration: 1500,
      });
      store.commit("setReloadMenu", true);
    } else {
      store.dispatch("openNotification", {
        message: "Error en la sincronizacion bancaria",
        duration: 1500,
      });
    }
  } catch (e) {
    console.log("Error en sincronización bancaria: " + e);
    store.dispatch("openNotification", {
      message: "Error en la sincronizacion bancaria:" + e,
      duration: 1500,
    });
  }
};

const sii = async () => {
  store.dispatch("openNotification", {
    message: "Actualizando información del SII",
    duration: 1500,
  });
  try {
    console.log("Sincronizando información del SII: " + store.state.inUseCompany);
    const vectiResult = await useSynchcronizeSII(store.state.inUseCompany);
    console.log("vectiResult: " + JSON.stringify(vectiResult));
    if (vectiResult) {
      store.dispatch("openNotification", {
        message: "Sincronización SII exitosa",
        duration: 1500,
      });
      console.log("setUpdateSII prev: " + store.state.updateSII);
      store.commit("setUpdateSII", true);
      console.log("setUpdateSII after: " + store.state.updateSII);
    } else {
      store.dispatch("openNotification", {
        message: "Error en la sincronizacion del SII",
        duration: 1500,
      });
    }
  } catch (e) {
    console.log("Error en sincronización del SII: " + e);
    store.dispatch("openNotification", {
      message: "Error en la sincronizacion del SII:" + e,
      duration: 1500,
    });
  }
};

const conciliation = async () => {
  store.dispatch("openNotification", {
    message: "Actualizando conciliacion automática",
    duration: 1500,
  });
  try {
    console.log("Actualizando información de conciliacion: " + store.state.inUseCompany);
    const vectiResult = await useSynchcronizeAutoconciliation(store.state.inUseCompany);
    console.log("vectiResult: " + JSON.stringify(vectiResult));
    if (vectiResult) {
      store.dispatch("openNotification", {
        message: "Actualización de conciliación exitosa",
        duration: 1500,
      });
    } else {
      store.dispatch("openNotification", {
        message: "Error en la actualizacion de conciliacion",
        duration: 1500,
      });
    }
  } catch (e) {
    console.log("Error en sincronización del SII: " + e);
    store.dispatch("openNotification", {
      message: "Error en la actualizacion de conciliacion:" + e,
      duration: 1500,
    });
  }
};

const month = new Date().toLocaleString("default", { month: "long" });
</script>
