import { PreCompany } from "../domain/PreCompany";
import { PreCompanyRepository } from "../domain/PreCompanyRepository";

export function getPreCompanyByLegalId(
  preCompanyRepository: PreCompanyRepository,
  legalId: string
): Promise<PreCompany> {
  console.log("GetPreCompanyByLegalId: legalId: " + legalId);
  return preCompanyRepository.getByLegalId(legalId);


}