import { ref } from "vue";
import { User } from "../context/Users/domain/User";
import { Dependencies } from "../context/Shared/infrastructure/Dependencies";
import { getUserData } from "../context/Users/application/getUserData";



// global state, created in module scope
const globalUser = ref(null as User | null);

//TODO: revisar si esta realmente seteando el globalUser.
export function useUser() {

  if (!globalUser.value) {

    getUserData(Dependencies.getInstance().getUserRepository()).then((user) => {
      globalUser.value = user;
    }).catch((error) => {
      console.log("Error getting user data (useUser):", JSON.stringify(error));
      if (error === "500") {
        console.log("Connection refused error");
        //console.log(JSON.stringify(store));
        store.dispatch("openNotification", { message: "Error, conexión rechazada", duration: 1500 });
        store.commit("setErrorConnection", true);
        //throw new Error("500");        
        //return Promise.reject("new 500");
      }

      //throw error;
    }).finally(() => {
      console.log("finally");
      console.log("error conex:" + store.getters.getErrorConnection)

      // console.log("globalUser:", JSON.stringify(globalUser));
      if (globalUser.value === null) {
        if (store.getters.getErrorConnection) {
          console.log("anular session");
        }

        console.log("globalUser nulo");
        // return null;
      }

    });



  }
  // console.log("globalUser:", JSON.stringify(globalUser));
  return globalUser;
}
