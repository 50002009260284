import { User } from "../domain/User";
import { UserRepository } from "../domain/UserRepository";

export function getUserData(userRepository: UserRepository): Promise<User> {
  return new Promise<User>((resolve, reject) => {
    userRepository
      .getUserData()
      .then((user) => {
        console.log("getUserData: ", JSON.stringify(user));
        resolve(user);
      })
      .catch((_error) => {
        console.log("Error getting user data:", JSON.stringify(_error));
        if (_error?.type === "ConnectionRefusedError") {
          console.log("Connection refused error");
          //throw new UserNotFoundError("User");
          reject("500");
          //reject(new UserNotFoundError("User not found 1"));
        }
        reject("404");
      });
  });
}
