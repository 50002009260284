import { ref, toValue, watchEffect } from "vue";
import { Dependencies } from "../context/Shared/infrastructure/Dependencies";
import { getUserExtendedData } from "../context/Users/application/getUserExtendedData";
import { UserExtended } from "../context/Users/domain/UserExtended";

import store from "../store/index";
import { Account } from "~/context/Accounts/domain/Account";
import { Router } from "vue-router";
import { usePreCompanyByUser } from "./usePreCompanies";

// global state, created in module scope
const globalUserExtended = ref(null as UserExtended | null);

export function useUserExtended(user: any, router: Router) {
  //console.log("********* llamnando useUserExtended");
  watchEffect(() => {
    if (!globalUserExtended.value) {
      // console.log("vuelvo a obtenerlo pq no tengo al userExt");
      //console.log("dentro de globalUserExtended.value:" + JSON.stringify(user));
      if (toValue(user) && toValue(user).uid !== undefined) {
        //console.log("toValue(user).uid: " + toValue(user).uid);
        const apiuser = toValue(user).uid.toString();
        //console.log("apiuser: " + apiuser);
        console.log("JSON.apiuser: " + JSON.stringify(apiuser));
        getUserExtendedData(Dependencies.getInstance().getUserRepository(), apiuser).then(
          async (user_ext) => {
            if (user_ext?.error != "Not Found") {
              globalUserExtended.value = user_ext;
              console.log("user_ext--->: " + JSON.stringify(user_ext));
              store.commit("setUserExtended", user_ext);
              store.commit("setInUseCompany", user_ext?.default_company);
              console.log("user_ext?.default_company en useUserExt:" + JSON.stringify(user_ext?.default_company));
              console.log("store.getters.getUserCompanies en useUserExt:" + JSON.stringify(store.getters.getUserCompanies));
              console.log("store.getters.getUserExtended.companies en useUserExt:" + JSON.stringify(store.getters.getUserExtended.companies));

              /*
              const companies = store.getters.getUserExtended.companies;

              if (companies) {
                const productoEncontrado = companies.companies.find((myCompany: any) => myCompany.uid === user_ext?.default_company);

                console.log("accounts en useUserExt:" + JSON.stringify(productoEncontrado));
              }
              const accounts = store.getters.getUserExtended.companies.get(user_ext?.default_company);
              console.log("accounts2 en useUserExt:" + JSON.stringify(accounts));
              if (accounts) {
                store.commit("setInUseCompanyHasAccount", accounts.length > 0);
              }
              */

            } else {
              globalUserExtended.value = user_ext;
              console.log("user extended no encontrado");
            }
            // store.dispatch('setInUseCompany', globalUserExtended.value?.default_company);

            //console.log("storeUserCompaniesAndAccounts");
            try {
              // console.log("globalUserExtended.value.companies:" + JSON.stringify(globalUserExtended.value.companies))
              if (
                globalUserExtended.value?.companies != undefined &&
                globalUserExtended.value.companies.length > 0
              ) {
                // store.commit("setUserCompanies", globalUserExtended.value.companies);

                globalUserExtended.value.companies.forEach(async (company) => {
                  const accounts: Account[] = await useAccountsCompany(
                    company.uid.toString()
                  );
                  const acc2 = JSON.parse(JSON.stringify(accounts));
                  console
                    .log
                    //   "----->almacenando account:" + JSON.stringify(accounts)
                    ();
                  //  console.log("cantidad de cuentas:" + acc2.accounts.length);
                  // console.log("cantidad de JSON.stringify(accounts):" + JSON.stringify(accounts));

                  store.commit("addUserCompanyAccount", acc2.accounts);
                });
              } else if (globalUserExtended.value?.companies === undefined) {
                console.log("El usuario no tiene compañias asociadas:");
                //validar si tiene precompanies
                try {
                  const precompanies = await usePreCompanyByUser("33333");

                  /*
                const precompanies = await usePreCompanyByUser(apiuser);
                if(precompanies!=null){
                  await 
                }
                  */
                  console.log("precompanies:" + JSON.stringify(precompanies));
                  //const router = useRouter();
                  //console.log("router: " + JSON.stringify(router));
                  router.push({
                    name: "/welcome/company-info",
                    query: { previously: true.toString() },
                  });
                } catch (error) {
                  console.error("Error en usePreCompanyByUser:" + error);
                }
              }
            } catch (error) {
              console.error("Error en getUserExtended:" + error);
            }
          }
        );
        //  console.log("----->ret: " + JSON.stringify(ret));
      } else {
        // Aqui el usuario no esta seteado
        //   console.log(
        // "tengo el usuario: " + JSON.stringify(globalUserExtended.value)
        //  );
      }
    }
  });

  //  console.log("user EXT!!!!: " + JSON.stringify(globalUserExtended.value));
  // console.log("default cia: " + JSON.stringify(globalUserExtended.value?.default_company));
  //  store.commit('setInUseCompany', globalUserExtended.value?.default_company);

  //this.$store.commit("setDefaultCompany", this.selectedCompany);
  return globalUserExtended;
}

export function updateUserExtended(companyId: any) {
  if (!globalUserExtended.value) {
    console.log("No deberia producirse pq el usuario ya esta seteado");
  } else {
    console.log("actualizando usuario:" + JSON.stringify(globalUserExtended));
    if (globalUserExtended.value && toValue(companyId)) {
      globalUserExtended.value.default_company = companyId;
    } else {
      console.log("no pude actualizar user extended");
    }
  }

  return globalUserExtended;
}
