import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router/auto";
import App from "./App.vue";

import "@mdi/font/css/materialdesignicons.min.css";

import "tailwindcss/tailwind.css";
import "../src/assets/css/modal-windows.css";
import "../src/assets/css/documents.css";
import { authGuard, createAuth0 } from "@auth0/auth0-vue";



import {
  vectiAppAuth0ClientId,
  vectiAppAuth0Domain,
  vectiAppAuth0RedirectUrl,
} from "./context/Shared/infrastructure/Config";


const router = createRouter({
  history: createWebHistory(),

});



router.beforeEach(async (to, _from, next) => {
  console.log('Navegando a:', to.path);
  //return true;

  if (to.path === '/register') {
    console.log('Redirigiendo a /register');
    next();
    return;
  };

  if (to.path === '/terms-conditions') {
    console.log('Redirigiendo a /terms-conditions');
    next();
    return;
  };

  if (to.path === '/success') {
    console.log('Redirigiendo a /exito');
    next();
    return;
  };

  if (to.path === '/fail') {
    console.log('Redirigiendo a /fallo');
    next();
    return;
  };


  if (to.path === '/') {
    console.log('Redirigiendo a /');
    next();
    return;
  };

  if (to.path !== "/" && to.path !== "/register" && to.path !== "/terms-conditions") {
    console.log('Ruta actual:', to.path);
    console.log('Guardando la ruta anterior:', to.fullPath);
    localStorage.setItem('previousRoute', to.fullPath);
  } else {
    console.log('Ruta actual 2:', to.path);
    console.log('removiendo la ruta anterior:', to.fullPath);
    localStorage.removeItem('previousRoute');
  }

  try {
    const isAuthenticated = await authGuard(to);

    if (isAuthenticated) {
      //console.log("Autenticado:" + JSON.stringify(to));
      next();
    } else {
      console.log("No autenticado");
      next("/")
      //next({ path: "/go", replace: true });
      return;
    }


  } catch (error) {
    console.error("Error en authGuard:", error);
    //next("/"); // Redirige a Landing en caso de error
  }

});



import store from "./store";
//import click from "./store/click";

const auth0 = createAuth0({
  domain: vectiAppAuth0Domain(), //TODO: Parametrizar
  clientId: vectiAppAuth0ClientId(),
  authorizationParams: {
    redirect_uri: vectiAppAuth0RedirectUrl(), //TODO: Parametrizar
    audience: "https://" + vectiAppAuth0Domain() + "/api/v2/", //TODO: Parametrizar
    //      skipRedirectCallback: true, // Evita que el callback funcione en cualquier URL
  },
});

const app = createApp(App);

// const authGuard = createAuthGuard(app);

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
});

app.use(vuetify);
app.use(router);

app.use(store);
//app.use(click);
app.use(auth0);

app.mount("#app");
