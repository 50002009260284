import { User } from "../domain/User";
import { UserRepository } from "../domain/UserRepository";
import { vectiAppApiUrl } from "../../Shared/infrastructure/Config";
import { APIRepository } from "../../Shared/infrastructure/APIRepository";
import { UserExtended } from "../domain/UserExtended";

export function createApiUserRepository(): UserRepository {
  return new UserAPIRepository();
}

export class UserAPIRepository extends APIRepository implements UserRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  getUserData(): Promise<User> {
    //console.log("UserAPIRepository:getUserData")
    return this.doGet<User>("/api/users/me", null).then(user => {
      console.log("UserAPIRepository:getUserData: user", JSON.stringify(user));
      return user;
      //return user;
    }).catch(error => {
      if (error?.status === 404) {
        console.log("UserAPIRepository:getUserData: User not found");
        throw new Error("User not found");
      } else if (error?.status === 500) {
        console.log("UserAPIRepository:getUserData: Connection refused");
        throw new Error("Connection refused");
      } else {
        console.log("status: " + error?.status);
        console.log("UserAPIRepository:getUserData", JSON.stringify(error));
        throw error;
      }

    });
  }

  getUserExtendedData(userId: string): Promise<UserExtended> {
    //console.log("UserAPIRepository:getUserExtendedData")
    try {
      const user = this.doGet<UserExtended>("/api/userext/" + userId);
      console.log("UserAPIRepository:getUserExtendedData: ", JSON.stringify(user));
      return user;
    } catch (error) {
      console.log("UserAPIRepository:getUserExtendedData ERROR: ", JSON.stringify(error));
      throw error;
    }
  }

  userUidDefaultCompany(_user: User): Promise<string> {
    console.log("user:  " + JSON.stringify(_user));
    return Promise.resolve("2");
  }
}
