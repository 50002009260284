import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { SubscriptionRepository } from "../domain/SubscriptionRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { Subscription } from "../domain/Subscription";

export function createApiSubscriptionRepository(): SubscriptionRepository {
  return new ApiSubscriptionRepository();
}

export class ApiSubscriptionRepository
  extends APIRepository
  implements SubscriptionRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  addSubscription(subscription: Subscription): Promise<void> {
    console.log("call addSubscription", JSON.stringify(subscription));
    try {
      return this.doPostNew("/api/subscription", null, {
        ...subscription,
        uid: subscription.uid.value,
        company_uid: subscription.company_uid.value,
      });
    } catch (error) {
      console.log("error addSubscription:" + error);
      return Promise.reject();
    }
  }
}
