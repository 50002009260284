import { PreCompany } from "../domain/PreCompany";
import { PreCompanyRepository } from "../domain/PreCompanyRepository";

export function getPreCompaniesByUser(
  preCompanyRepository: PreCompanyRepository,
  userId: string
): Promise<PreCompany> {
  console.log("getPreCompaniesByUser");
  return preCompanyRepository.getByUser(userId);


}