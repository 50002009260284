<template>
  <v-navigation-drawer
    width="300"
    v-model="drawer"
    :rail="expand"
    permanent
    style="background-color: #1f2937; color: white"
  >
    {{ store.getters.getInUseCompanyHasAccount }} //
    <div
      v-if="
        store.getters.getInUseCompanyHasAccount ||
        store.getters.getInUseCompanyHasTaxAgency
      "
    >
      <div v-for="(item, idx) in menuItems" :key="idx">
        <v-list
          density="compact"
          nav
          :opened="opened"
          @update:opened="(newOpened) => (opened = newOpened.slice(-1))"
        >
          <v-list-item
            v-if="!item.subSec"
            :prepend-icon="`mdi-${item.iconName}`"
            :title="item.name"
            :to="item.to"
            @click="selections(item.to)"
            rounded="lg"
          >
          </v-list-item>

          <v-list-group v-else fluid color="orange">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                :title="item.name"
                :prepend-icon="`mdi-${item.iconName}`"
                @click="selections(item.to)"
              ></v-list-item>
            </template>
            <div v-for="(subItem, idy) in item.items" :key="idy">
              <v-list-item
                :title="subItem.name"
                :prepend-icon="`mdi-${subItem.iconName}`"
                :value="subItem.name"
                :to="subItem.to"
                @click="selections(subItem.to)"
                rounded="lg"
              ></v-list-item>
            </div>
          </v-list-group>
        </v-list>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script setup>
import { vectiAppIsStageProd } from "../context/Shared/infrastructure/Config";
import { useAuth0 } from "@auth0/auth0-vue";
import { computed } from "vue";

const currentKey = ref(0);
const windowWidth = ref(window.innerWidth);
const getMenuSelection = ref(store.getters.getMenuSelection);
const sidebarOpen = ref(true);
const opened = ref([]);
const { isLoading } = useAuth0();
const getConfig = ({ accounts }) => {
  return {
    agregarCuenta: !accounts || accounts.length === 0,
  };
};

const taxNotConfigurated = [{ name: "Debes configurar el SII", iconName: "cog", to: "" }];

const accountNotConfigurated = [
  { name: "Debes configurar una cuenta", iconName: "cog", to: "" },
];

const taxAndAccountNotConfigurated = [
  { name: "Debes configurar el SII y una cuenta", iconName: "cog", to: "" },
];

const itemsInvoicesAndMore = [
  {
    name: "Todos los documentos",
    iconName: "receipt-text-outline",
    to: "/documents",
  },
  { name: "Todos los gastos/ingresos", iconName: "cash-fast", to: "/expenses" },
  {
    name: "Traer documentos tributarios",
    iconName: "invoice-text-plus-outline",
    to: "/synchronizations/sii",
  },
  { name: "Conciliados 100%", iconName: "cash-check", to: "/outinreconciliations" },
];

const itemsMovementsAndMore = [
  { name: "Movimientos pendientes", iconName: "cash-fast", to: "/movements-all" },
  {
    name: "Traer movimientos",
    iconName: "cash-check",
    to: "/synchronizations/movements",
  },
  { name: "Conciliados 100%", iconName: "cash-check", to: "/reconciliations" },
  {
    name: "Conciliados Automáticos",
    iconName: "cash-check",
    to: "/autoreconciliations",
  },
];

const itemsConciliationsAndMore = [
  {
    name: "Efectuar Autoconciliacion",
    iconName: "cash-plus",
    to: "/synchronizations/autoreconciliations",
  },
];

const itemsBusinessPartnersAndMore = [
  { name: "Clientes", iconName: "account-tie", to: "/businesspartners/clients" },
  {
    name: "Proveedores",
    iconName: "account-group-outline",
    to: "/businesspartners/providers",
  },
  {
    name: "Colaboradores",
    iconName: "account-multiple",
    to: "/businesspartners/collaborators",
  },
  { name: "Empleados", iconName: "account-cog", to: "/businesspartners/employees" },
];

const itemsExpensesAndMore = [
  { name: "Ingresos", iconName: "cash-plus", to: "/expenses/createSpotIncomeExpense" },
  { name: "Gastos", iconName: "account-cash", to: "/expenses/createSpotExpense" },
  { name: "Impuestos", iconName: "book-edit-outline", to: "/expenses/createExpense" },
  {
    name: "Remuneraciones",
    iconName: "badge-account-outline",
    to: "/expenses/createSalary",
  },
];

const itemsConfigurationsAndMore = [
  { name: "Mi Compañia", iconName: "inbox", to: "/company" },
  { name: "Mis cuentas", iconName: "account-details", to: "/accounts" },
  { name: "Mis accesos", iconName: "edit-settings-24-filled", to: "/access" },
];

const getItemsInvoicesAndMore = () => {
  if (store.getters.getInUseCompanyHasTaxAgency) {
    return itemsInvoicesAndMore;
  } else {
    return taxNotConfigurated;
  }
};

const getItemsMovementsAndMore = () => {
  if (store.getters.getInUseCompanyHasAccount) {
    return itemsMovementsAndMore;
  } else {
    return accountNotConfigurated;
  }
};

const getItemsConciliationsAndMore = () => {
  if (
    store.getters.getInUseCompanyHasAccount &&
    store.getters.getInUseCompanyHasTaxAgency
  ) {
    return itemsConciliationsAndMore;
  } else {
    return taxAndAccountNotConfigurated;
  }
};

const getItemsBusinessPartnersAndMore = () => {
  if (store.getters.getInUseCompanyHasTaxAgency) {
    return itemsBusinessPartnersAndMore;
  } else {
    return taxNotConfigurated;
  }
};

const getItemsExpensesAndMore = () => {
  return itemsExpensesAndMore;
};

const getItemsConfigurationsAndMore = () => {
  return itemsConfigurationsAndMore;
};

const menuItems = computed(() => {
  return [
    { name: "Inicio", iconName: "home-circle", subSec: false, to: "/start" },

    {
      name: "Movimientos Bancarios",
      to: "movementAndMore",
      iconName: "bank",
      subSec: true,
      isOpen: false,
      items: getItemsMovementsAndMore(),
    },

    {
      name: "Facturas, boletas y gastos",
      to: "invoicesAndMore",
      iconName: "invoice-text-multiple-outline",
      subSec: true,
      isOpen: false,
      items: getItemsInvoicesAndMore(),
    },
    {
      name: "Conciliaciones",
      iconName: "invoice-text-multiple-outline",
      to: "conciliationAndMore",
      subSec: true,
      isOpen: false,
      items: getItemsConciliationsAndMore(),
    },

    {
      name: "Socios Comerciales",
      iconName: "briefcase-account",
      subSec: true,
      isOpen: false,
      to: "businessPartersAndMore",
      items: getItemsBusinessPartnersAndMore(),
    },

    {
      name: "Registro de Gastos/Ingresos",
      iconName: "application-edit-outline",
      subSec: true,
      isOpen: false,
      to: "ExpensesAndMore",
      items: getItemsExpensesAndMore(),
    },

    {
      name: "Configuración",
      iconName: "cog",
      subSec: true,
      isOpen: false,
      to: "configurationAndMore",
      items: getItemsConfigurationsAndMore(),
    },
    !vectiAppIsStageProd() && {
      name: "Test",
      iconName: "test-tube-empty",
      subSec: false,
      to: "/test",
    },
  ];
});

const updateWidth = () => {
  windowWidth.value = window.innerWidth;
};

const reloadComponent = (to) => {
  console.log("reloadComponent:" + to);
  console.log("currentKey:" + currentKey.value);
  console.log("route.path:" + route.path);
  /*
            if (route.path === to) {
              // Si ya estamos en la ruta, forzamos la recarga de la misma
              currentKey.value++;
            } else {
              // Navegamos normalmente si no es la misma ruta
              //router.push(to).catch(() => { });
            }
              */
};

const resetSelection = () => {
  store.dispatch("resetMenuSelection");
};

const selections = (url) => {
  console.log("seleccion: " + url);
  resetSelection();
  store.dispatch("reloadComponent");
  console.log("url: " + url);

  switch (url) {
    case "/movements-all":
      console.log("all movements");
      getMenuSelection.value.movementsAll = true;
      store.commit("setMenuSelection", getMenuSelection);
      store.commit("setReloadMenu", true);
      break;
    case "/movements":
      console.log("movements");
      getMenuSelection.value.movements = true;
      store.commit("setMenuSelection", getMenuSelection);
      break;
    case "/company":
      getMenuSelection.value.company = true;
      store.commit("setMenuSelection", getMenuSelection);
      break;
    case "/invoices":
      getMenuSelection.value.invoices = true;
      store.commit("setMenuSelection", getMenuSelection);
      break;
    case "/documents":
      getMenuSelection.value.documents = true;
      store.commit("setMenuSelection", getMenuSelection);
      break;
    case "/outcomes":
      getMenuSelection.value.outcomes = true;
      store.commit("setMenuSelection", getMenuSelection);
      break;
    case "/incomes":
      getMenuSelection.value.incomes = true;
      store.commit("setMenuSelection", getMenuSelection);
      break;
    case "/reconciliations":
      getMenuSelection.value.reconciliations = true;
      store.commit("setMenuSelection", getMenuSelection);
      break;
    case "/businesspartners/clients":
      getMenuSelection.value.clients = true;
      store.commit("setMenuSelection", getMenuSelection);
      break;
    case "/businesspartners/providers":
      getMenuSelection.value.providers = true;
      store.commit("setMenuSelection", getMenuSelection);
      break;
    case "/businesspartners/collaborators":
      getMenuSelection.value.collaborators = true;
      store.commit("setMenuSelection", getMenuSelection);
      break;
    case "/businesspartners/employees":
      getMenuSelection.value.employees = true;
      store.commit("setMenuSelection", getMenuSelection);
      break;

    default:
      break;
  }
};

const drawer = ref(true);
let accountIsSetup = ref(false);
const accounts = store.getters.getUserCompanies.get(store.getters.getInUseCompany);
//console.log("accounts en la carga:" + store.getters.getInUseCompany + " " + JSON.stringify(accounts));
//console.log("tiene cuenta?:" + JSON.stringify(accountIsSetup));
//console.log("getMenuSelection:" + JSON.stringify(store.getters.getMenuSelection));

//const menuItems = ref(getMenuItems(getConfig({ accounts })));
const expand = ref(store.getters.getExpandMenu);

onMounted(() => {
  window.addEventListener("resize", updateWidth);
  if (window.innerWidth < 800) {
    store.commit("setExpandMenu", true);
  }
});
onUnmounted(() => {
  window.removeEventListener("resize", updateWidth);
});
watch(
  () => accountIsSetup,
  (newValue) => {
    console.log("*******************accountIsSetup:" + accountIsSetup);
  }
);

watch(
  () => store.getters.getExpandMenu,
  (newValue) => {
    console.log("cambio de menu:" + newValue);
    expand.value = newValue;
  }
);
watch(
  () => store.getters.getInUseCompany,
  (newValue) => {
    // console.log("cambio de compañia: " + newValue);
    // console.log("reload menu: " + store.getters.getReloadMenu);
    store.commit("setReloadMenu", true);
    //  console.log("reload menu: " + store.getters.getReloadMenu);
    const accounts = store.getters.getUserCompanies.get(newValue);
    console.log("newValue:" + newValue + " --> " + JSON.stringify(accounts));
    if (!accounts || accounts.length === 0) {
      //  console.log("Muestra Agregar Cuenta")
      // menuItems.value = getMenuItems({ agregarCuenta: true });
      //  console.log("store.getters.getInUseCompanyHasAccount:" + store.getters.getInUseCompanyHasAccount);
      store.commit("setInUseCompanyHasAccount", false);

      accountIsSetup.value = false;
    } else {
      // console.log("Oculta Agregar Cuenta")
      // menuItems.value = getMenuItems({ agregarCuenta: false });
      //  store.commit("setDataSetup", { taxAgency: true, account: true });
      // console.log("store.getters.getInUseCompanyHasAccount: " + store.getters.getInUseCompanyHasAccount);
      store.commit("setInUseCompanyHasAccount", true);
      //store.getters.getInUseCompanyHasAccount.set(false);
      accountIsSetup.value = true;
    }

    //        if(store.getters.)
  }
);
watch(windowWidth, (newWidth, oldWidth) => {
  console.log("el menu está:" + expand.value);

  if (newWidth < 800 && !expand.value) {
    store.commit("setExpandMenu", true);
  } else if (newWidth >= 800 && expand.value) {
    store.commit("setExpandMenu", false);
  }
});
</script>
